import { useOutletContext } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import ReceiveTransactionItemInfoModal from "./ReceiveTransactionItemInfoModal";

const ReceiveTransactionItemView = ({ items }) => {
  const { matches } = useOutletContext();

  return (
    <Box
      sx={{
        width: "100%",
        height: matches
          ? `${window.innerHeight - 234.75}px`
          : "calc(100vh - 255px)",
        overflowY: "scroll",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {items
        .filter((item) => item.lineitemqtypendingreceipt !== 0)
        .map((item, index) => (
          <Paper
            key={index}
            elevation={1}
            sx={{ py: 1, px: 1, mb: index !== items.length - 1 ? 1 : 0 }}
          >
            <FlexWrapper justifyContent="space-between">
              <FlexWrapper alignItems="center">
                <Typography variant="h6">{item.lineitemname}</Typography>
                <ReceiveTransactionItemInfoModal item={item} />
              </FlexWrapper>
              {item.lineitemqty - item.lineitemqtyreceived === 0 ? (
                <Typography color="green" variant="subtitle1">
                  COMPLETE
                </Typography>
              ) : (
                <Typography variant="h6" gutterBottom>
                  Qty To Receive: {item.lineitemqty - item.lineitemqtyreceived}
                </Typography>
              )}
            </FlexWrapper>
            {item.lineitemdescription && (
              <Typography variant="caption" display="block">
                {item.lineitemdescription}
              </Typography>
            )}
            <Typography variant="caption" fontWeight="bold">
              {item.lineitemclass} - {item.lineitemboxsize}
            </Typography>
          </Paper>
        ))}
    </Box>
  );
};
export default ReceiveTransactionItemView;
