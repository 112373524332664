import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { GroupAdd, Print } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  gridExpandedSortedRowIdsSelector,
  GridToolbar,
  GridToolbarContainer,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import {
  ORDERPROCESSINGCOLUMNS,
  ORDERPROCESSINGCOLUMNSWGE,
} from "../../../../utils/columns/order-processing/orderProcessingColumns";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const filteredIds = useGridSelector(apiRef, gridExpandedSortedRowIdsSelector);
  // Map the IDs to get the actual row data
  const filteredRows = filteredIds.map((id) => apiRef.current.getRow(id));
  const selectedRows = apiRef.current.getSelectedRows(); //returns Map object
  // Convert Map to Array of Objects
  const convertedArray = Array.from(selectedRows, ([key, value]) => {
    return {
      ...value,
      "SO #": key, // Replace the 'SO #' field with the stripped key value
    };
  });

  const totalProjectedPickQtySelected = convertedArray?.length
    ? convertedArray.reduce((acc, current) => {
        return acc + parseInt(current["Projected Pick Qty"]);
      }, 0)
    : 0;
  const numOfOrders = filteredRows.length;
  const numOfLines = filteredRows.length
    ? filteredRows.reduce((acc, current) => {
        return acc + parseInt(current["Number of Lines"]);
      }, 0)
    : 0;
  const numOfUnits = filteredRows?.length
    ? filteredRows.reduce((acc, current) => {
        return acc + parseInt(current["Total Qty"]);
      }, 0)
    : 0;
  const numOfAllocatedLines = numOfLines;
  const numOfAllocatedUnits = filteredRows?.length
    ? filteredRows.reduce((acc, current) => {
        return acc + parseInt(current["Projected Pick Qty"]);
      }, 0)
    : 0;

  const headerValues = [
    {
      headerName: "Number of Orders",
      headerValue: numOfOrders,
      bgColor: "#da1818",
    },
    {
      headerName: "Number of Lines",
      headerValue: numOfLines,
      bgColor: "#ff8800",
    },
    {
      headerName: "Number of Units",
      headerValue: numOfUnits,
      bgColor: "#982f94",
    },
    {
      headerName: "Number of Allocated Lines",
      headerValue: numOfAllocatedLines,
      bgColor: "#692aa4",
    },
    {
      headerName: "Number of Allocated Units",
      headerValue: numOfAllocatedUnits,
      bgColor: "#2897ca",
    },
  ];

  return (
    <GridToolbarContainer sx={{ p: 0 }}>
      <FlexWrapper
        width="100%"
        justifyContent="space-between"
        alignItems="stretch"
      >
        {headerValues?.length
          ? headerValues.map((header) => (
              <Box
                color="white"
                bgcolor={header.bgColor}
                textAlign="right"
                flex={1}
                p={1}
              >
                <Stack>
                  <Typography variant="h6">{header.headerName}</Typography>
                  <Typography variant="body2">{header.headerValue}</Typography>
                </Stack>
              </Box>
            ))
          : null}
      </FlexWrapper>

      <FlexWrapper
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <GridToolbar
          showQuickFilter
          csvOptions={{ fileName: `stock-orders-${Date.now()}` }}
          printOptions={{ disableToolbarButton: true }}
          sx={{ pl: 1, pt: 3 }}
        />

        {convertedArray.length ? (
          <FlexWrapper gap={1} alignItems="center">
            <Typography pr={1}>
              <strong>Selected Pick Qty: </strong>{" "}
              {totalProjectedPickQtySelected?.toString()}
            </Typography>
            <Button
              color="success"
              size="small"
              variant="contained"
              endIcon={<GroupAdd />}
            >
              Assign Pickers
            </Button>
            <Button
              size="small"
              variant="contained"
              endIcon={<Print />}
              sx={{ mr: 1 }}
            >
              Print Orders
            </Button>
          </FlexWrapper>
        ) : null}
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const OrderProcessing = () => {
  const [orderData, setOrderData] = useState([]);
  const [whsePickers, setWhsePickers] = useState([]);
  const [loading, setLoading] = useState("");
  const apiRef = useGridApiRef();
  const { currentNetsuiteLocationIds, currentSubsidiary } = useSelector(
    (state) => state.user
  );
  const { openAlert } = useGeneralAlert();

  const columns =
    currentSubsidiary === "WGE"
      ? ORDERPROCESSINGCOLUMNSWGE
      : ORDERPROCESSINGCOLUMNS;

  useEffect(() => {
    getOrderdata();
  }, []);

  const getOrderdata = async () => {
    setLoading("Loading Stock Orders...");
    try {
      // Run both requests concurrently
      const [nsPickersResponse, nsOrdersResponse] = await Promise.all([
        axios.get("netsuite/order-processing/get/warehouse-pickers"),
        axios.get(
          `netsuite/order-processing/get/orders?locations=${JSON.stringify(
            currentNetsuiteLocationIds
          )}&subsidiary=${currentSubsidiary}`
        ),
      ]);

      setWhsePickers(
        nsPickersResponse.data?.pickers?.length
          ? nsPickersResponse.data.pickers.map((pickerObj) => {
              return { name: pickerObj.name, id: pickerObj.id };
            })
          : []
      );
      setOrderData(nsOrdersResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (loading) {
    return <Loading message={loading} />;
  }

  return (
    <Box p={1}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Stock Orders
      </Typography>

      <DataGrid
        disableRowSelectionOnClick
        apiRef={apiRef}
        checkboxSelection={orderData?.length && currentSubsidiary === "AIT"}
        sx={{ height: "80vh" }}
        slots={{
          toolbar: currentSubsidiary === "WGE" ? GridToolbar : CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: { fileName: `orders-${Date.now()}` },
            printOptions: { disableToolbarButton: true },
          },
        }}
        density="compact"
        rows={orderData}
        columns={columns}
        getRowId={(row) => row["SO #"]?.replace(/<\/?[^>]+(>|$)/g, "")}
      />
    </Box>
  );
};
export default OrderProcessing;
