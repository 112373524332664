import axios from "../../axios/axios.config";

export const printPickOrderPalletLabel = async ({
  currentPrinter,
  tranid,
  username,
  brand,
  shippingAddress,
  poNumber,
  shipMethod,
  warehouseNotes,
  alertFn,
  title,
  currentLocationInternalId,
}) => {
  try {
    if (!currentPrinter) {
      throw new Error(
        "No Printer is assigned. Please select a printer in your settings."
      );
    }

    const locationAddressResponse = await axios.get(
      `locations/get/location-address?locationinternalid=${currentLocationInternalId}`
    );

    if (!locationAddressResponse?.data?.address) {
      throw new Error("No Location Address Found.");
    }

    alertFn({
      type: "success",
      message: "Printing Label...",
      duration: 2000,
    });

    const template = `^XA^MMT^PW812^LL1218^LS0
^FT75,100^BY3,2,90^BCN,,N,N^FD${tranid}^FS
^FT90,160^A0N,75,85^FD${tranid}^FS
^FT350,50^FB400,1,0,R,0^A0N,30^FD${username}^FS
^FT420,330^A0N,150^FD${brand}^FS
^FX
^FO30,180^GB750,300,2^FS
^FT50,225^A0N,30^FDFROM/DE:^FS
^FT50,390^FB800,5,0,L,0^A0N,30^FD${locationAddressResponse?.data?.address?.replace(
      /\n/g,
      "\\&"
    )}^FS
^FX

^FX
^FO30,478^GB750,300,2^FS
^FT50,525^A0N,30^FDTO/A:^FS
^FT50,690^FB800,5,0,L,0^A0N,30^FD${shippingAddress?.replace(/\r\n/g, "\\&")}^FS

^FX

^FX
^FT40,825^FB800,1,0,L,0^A0N,30^FDPO #: ${poNumber}^FS
^FT40,875^FB800,1,0,L,0^A0N,30^FDShipping Method: ${shipMethod}^FS
^FT40,1045^FB740,5,0,L,0^A0N,30^FDWarehouse Notes: ${warehouseNotes}^FS
^FX

^FT40,1175^A0N,45^FDPALLET #:__________^FS

^PQ1,0,1,Y^XZ`;

    let printJobUrl = "https://api.printnode.com/printjobs";
    let apiID = process.env.REACT_APP_PRINT_API_ID;
    let body = {
      printerId: currentPrinter.printnodeid,
      title,
      contentType: "raw_base64",
      content: btoa(template),
      source: "MERN > Pick Order > printPalletLabel",
    };

    await axios.post(printJobUrl, body, {
      headers: {
        Authorization: "Basic " + btoa(apiID),
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    alertFn({
      type: "error",
      message: `Could not print label: ${error.message}`,
      duration: 5000,
    });
  }
};
